/*
 * @Description: common
 * @Author: zhangguoliang
 * @Date: 2021-08-12 17:56:40
 * @LastEditors: sunchen
 * @LastEditTime: 2021-10-20 11:07:22
 */

import { Tag } from '@/interface';
import { Module } from 'vuex';
import RootState, { CommonState } from './interface';

const common: Module<CommonState, RootState> = {
  state: {
    tags: [],
    dictList: [],
  },
  mutations: {
    SET_TAGS(state, tags: Tag[]) {
      state.tags = tags;
    },
    SET_DICTLIST(state, dictList: string[]) {
      state.dictList = dictList;
    },
  },
  getters: {
    tags(state) {
      return state.tags;
    },
    dictList(state) {
      return state.dictList;
    },
  },
};

export default common;
