/*
 * @Description: user state
 * @Author: zhangguoliang
 * @Date: 2021-08-03 09:47:14
 * @LastEditors: sunchen
 * @LastEditTime: 2024-01-30 20:21:00
 */

import router, { errorRoutes } from '@/router';
import { isEmpty } from 'lodash';
import { Module } from 'vuex';
import { LoginByPassword, LoginBySmsCode, MenuItem } from '@/interface';
import RootState, { UserState } from './interface';
import { login } from '@/api/auth';

/**
 * @description: 获取所有的路由列表
 * @param {any} menus
 * @return {*}
 */
function flatMapRoutes(menus: MenuItem[]): MenuItem[] {
  return menus
    ?.map((menu) => {
      if (isEmpty(menu.children)) {
        return menu;
      }
      // 为了刷新页面菜单栏定位到刷新前状态, 属性添加父菜单path
      return flatMapRoutes(
        menu.children?.map((item) => ({
          ...item,
          subMenuKey: [...(menu.subMenuKey || []), menu.path],
        })) || []
      );
    })
    .flat(5);
}

/**
 * @description: 生成动态路由
 * @param {MenuItem} routes
 * @return {*}
 */
function genRoutes(routes: MenuItem[]) {
  routes.forEach(({ path, location, ...meta }) => {
    const p = path.replace(/^\//, '');
    const local = location.replace(/^\//, '');
    router.addRoute('root', {
      path: p,
      name: p,
      component: () => import(`@/views/${local}`),
      meta,
    });
  });
  errorRoutes.forEach((route) => {
    router.addRoute(route);
  });
}

/**
 * @description: 过滤菜单，隐藏hidden: true的项
 * @param {*}
 * @return {*}
 */
function filterMenus(menus: MenuItem[]): MenuItem[] {
  return menus
    .filter((menu) => !menu.hidden)
    .map((menu) => ({
      ...menu,
      children: filterMenus(menu.children || []),
    }));
}

const menus = [
  {
    path: '/home',
    location: 'home',
    icon: 'icon-gongzuotai',
    title: '工作台',
  },
  // {
  //   path: '/setting',
  //   location: 'setting',
  //   icon: 'icon-shezhi',
  //   title: '系统设置',
  //   children: [
  //     {
  //       path: '/setting/excel',
  //       location: 'setting/excel/index',
  //       title: 'excel模板',
  //     },
  //     {
  //       path: '/setting/excel/sheets',
  //       location: 'setting/excel/sheets',
  //       title: 'sheet表配置',
  //       hidden: true,
  //     },
  //     {
  //       path: '/setting/excel/checkSetting',
  //       location: '/setting/excel/checkSetting',
  //       title: '查看配置',
  //       hidden: true,
  //     },
  //   ],
  // },
  {
    path: '/platform',
    location: '/platform',
    icon: 'icon-pingtai',
    title: '平台',
    children: [
      {
        path: '/platform/appManage/details',
        location: 'platform/appManage/details',
        title: '产品详情',
        hidden: true,
      },
      {
        path: '/platform/productResourcePool',
        location: 'platform/productResourcePool/index',
        title: '产品基础资源池',
        hidden: true,
      },
      {
        path: '/platform/tenantManage',
        location: 'platform/tenantManage/index',
        title: '租户管理',
      },
      {
        path: '/platform/tenantManage/details',
        location: 'platform/tenantManage/details',
        title: '企业租户详情',
        hidden: true,
      },
      {
        path: '/platform/tenantManage/personalDetails',
        location: 'platform/tenantManage/personalDetails',
        title: '个人租户详情',
        hidden: true,
      },
      {
        path: '/platform/userManage',
        location: 'platform/userManage/index',
        title: '用户管理',
      },
      {
        path: '/platform/userManage/details',
        location: 'platform/userManage/details',
        title: '用户详情',
        hidden: true,
      },
      {
        path: '/manager',
        location: '/manager/list',
        title: '管理员管理',
      },
      {
        path: '/roleManage',
        location: '/roleManage/roleList',
        title: '平台角色',
      },
      {
        path: '/platform/dictionaryList',
        location: '/platform/dictionaryList/index',
        title: '字典管理',
        // hidden: true,
      },
      {
        path: '/platform/dictionaryList/valueList',
        location: '/platform/dictionaryList/valueList',
        title: '字典值列表',
        hidden: true,
      },
      {
        path: '/platform/dictionaryList/eventList',
        location: '/platform/dictionaryList/eventList',
        title: '事件列表',
        hidden: true,
      },
    ],
  },
  {
    path: '/operate',
    location: '/operate',
    icon: 'icon-yunying',
    title: '运营',
    children: [
      {
        path: '/memberLevel',
        location: '/memberLevel/index',
        title: '会员等级',
      },
      {
        path: '/equityCenter',
        location: '/equityCenter/index',
        title: '平台权益中心',
      },
      // {
      //   path: '/operate/intendedCustomer',
      //   location: 'operate/intendedCustomer/index',
      //   title: '线索客户',
      // },
      {
        path: '/platform/appManage',
        location: 'platform/appManage/index',
        title: '产品管理',
      },
      {
        path: '/operate/appShelf',
        location: 'operate/appShelf/index',
        title: '产品货架',
      },
      {
        path: '/operate/appShelf/create',
        location: 'operate/appShelf/create',
        title: '创建产品货架',
        hidden: true,
        keepAlive: true,
      },
      {
        path: '/operate/appShelf/details',
        location: '/operate/appShelf/details',
        title: '产品货架详情',
        hidden: true,
      },
      {
        path: '/platform/certificationAudit',
        location: 'platform/certificationAudit/index',
        title: '认证审核',
      },
      {
        path: '/platform/certificationAudit/details',
        location: 'platform/certificationAudit/details',
        title: '企业认证审核详情',
        hidden: true,
      },
      {
        path: '/platform/certificationAudit/personalDetails',
        location: 'platform/certificationAudit/personalDetails',
        title: '个人认证审核详情',
        hidden: true,
      },
      {
        path: '/log',
        location: '/log/index',
        title: '登陆日志',
      },
      {
        path: '/order',
        location: '/order/orderList',
        title: '订购管理',
      },
      {
        path: '/invoice',
        location: '/invoice/invoiceList',
        title: '发票管理',
      },
      {
        path: '/invoice/invoiceDetail',
        location: '/invoice/invoiceDetail',
        title: '发票详情',
        hidden: true,
      },
      {
        path: '/operate/onlineCoupon/couponTemplate',
        location: 'operate/onlineCoupon/couponTemplate/index',
        title: '线上优惠券模板',
      },
      {
        path: '/operate/onlineCoupon/couponDistribute',
        location: 'operate/onlineCoupon/couponDistribute/index',
        title: '线上优惠券派发',
      },
      {
        path: '/operate/offlineVouche',
        location: 'operate/offlineVouche/index',
        title: '线下代金券管理',
      },
      {
        path: '/operate/makeBid',
        location: 'makeBid/index',
        title: '标书代做',
      },
      {
        path: '/operate/consultList',
        location: 'operate/consultList/index',
        title: '工程咨询管理列表',
      },
      {
        path: '/portalSite/bannerManage',
        location: 'portalSite/bannerManage/index',
        title: '门户banner管理',
      },
      {
        path: '/portalSite/newsManage',
        location: 'portalSite/newsManage/index',
        title: '门户行业资讯管理',
      },
      {
        path: '/portalSite/feedbackManage',
        location: 'portalSite/feedbackManage/index',
        title: '门户反馈管理',
      },
      {
        path: '/portalSite/download',
        location: 'portalSite/download/index',
        title: '下载中心',
      },
      {
        path: '/activity/distributionManage',
        location: 'activity/distributionManage/index',
        title: '活动分销统计',
      },
    ],
  },
  // {
  //   path: '/lock',
  //   location: '/lock',
  //   icon: 'icon-yunying',
  //   title: '加密锁管理',
  //   children: [
  //     {
  //       path: '/lock/manage',
  //       location: 'lock/manage',
  //       title: '加密锁管理',
  //     },

  //     {
  //       path: '/lock/service',
  //       location: 'lock/service',
  //       title: '客服中心',
  //     },
  //     {
  //       path: '/lock/lockDetail',
  //       location: 'lock/lockDetail',
  //       title: '加密锁详情',
  //       hidden: true,
  //     },
  //   ],
  // },
];

const user: Module<UserState, RootState> = {
  state: {
    token: '',
    menus: [],
  },
  mutations: {
    SET_TOKEN(state, token: string) {
      state.token = token;
    },
    SET_MENUS(state, menus: MenuItem[]) {
      state.menus = menus;
    },
  },
  actions: {
    login({ commit }, payload: LoginByPassword | LoginBySmsCode) {
      return login(payload).then((res) => {
        const { access_token } = res;
        commit('SET_TOKEN', access_token);
        return access_token;
      });
    },
    logout({ commit }) {
      commit('SET_TOKEN', '');
      commit('SET_MENUS', []);
      commit('SET_TAGS', []);
      sessionStorage.clear();
    },
    /**
     * @description: 菜单生成路由规则，如果menuItem有children且不为空，获取children里的项作为路由，忽略其他属性
     * @param {*} param1
     * @return {*}
     */
    menus({ commit }) {
      genRoutes(flatMapRoutes(menus));
      commit('SET_MENUS', filterMenus(menus));
      return Promise.resolve(menus);
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    menus(state) {
      return state.menus;
    },
  },
};

export default user;
