/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 10:26:26
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2022-10-18 11:26:08
 */
require('@/components/global/index');
import '@/assets/style/commons.less';

import Vue from 'vue';
import router from './router';
import store from './store';
import {
  Icon,
  Button,
  message,
  FormModel,
  Input,
  Menu,
  Layout,
  Breadcrumb,
  Tabs,
  Select,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Upload,
  Table,
  Divider,
  Tag,
  Dropdown,
  Row,
  Col,
  Modal,
  Spin,
  Tree,
  InputNumber,
  Pagination,
  ConfigProvider,
  List,
  Avatar,
  Steps,
  Popover,
  Tooltip,
  Transfer,
  Popconfirm,
  Descriptions,
  Timeline,
  Empty,
  Card,
  Space,
  Cascader,
} from 'ant-design-vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import '@/permission';
import { readFromStorage, save2Storage } from '@/utils/store2storage';
import App from './App.vue';

Vue.config.productionTip = false;

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2957314_kkrsl26im4p.js',
});

window.addEventListener('beforeunload', save2Storage);

readFromStorage();

Vue.component('IconFont', IconFont);

Vue.use(Button)
  .use(FormModel)
  .use(Input)
  .use(Layout)
  .use(Breadcrumb)
  .use(Tabs)
  .use(Select)
  .use(Radio)
  .use(Menu)
  .use(Switch)
  .use(Checkbox)
  .use(DatePicker)
  .use(Upload)
  .use(Table)
  .use(Divider)
  .use(Tag)
  .use(Dropdown)
  .use(Row)
  .use(Col)
  .use(Modal)
  .use(Spin)
  .use(Tree)
  .use(InputNumber)
  .use(Icon)
  .use(Pagination)
  .use(ConfigProvider)
  .use(List)
  .use(Avatar)
  .use(Steps)
  .use(Tooltip)
  .use(Popover)
  .use(Transfer)
  .use(Popconfirm)
  .use(Descriptions)
  .use(Timeline)
  .use(Empty)
  .use(Card)
  .use(Cascader)
  .use(Space);
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$zhCN = zhCN;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
