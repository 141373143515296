





















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import SubMenu from './subMenu.vue';

export default Vue.extend({
  components: {
    SubMenu,
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedKeys: [this.$route.path],
      openKeys: this.$route.meta?.subMenuKey,
    };
  },
  watch: {
    '$route.path'(val) {
      this.selectedKeys = [val];
      this.openKeys = this.$route.meta?.subMenuKey;
    },
  },
  methods: {
    itemClick({ key }: { key: string }) {
      if (key === this.$route.path) return;
      this.$router.push({ path: key });
    },
    toHome() {
      this.$router.push({ path: '/' });
    },
  },
  computed: {
    ...mapGetters(['menus']),
  },
});
