<!--
 * @Descripttion: 
 * @Author: yuyang
 * @Date: 2021-12-04 11:05:56
 * @LastEditors: yuyang
 * @LastEditTime: 2022-01-06 14:57:12
-->
<template>
  <div class="editorWrap">
    <!-- 工具栏 -->
    <Toolbar
      class="toolbar"
      :editorId="editorId"
      :defaultConfig="toolbarConfig"
    />
    <!-- 编辑器 -->
    <Editor
      class="editor"
      :editorId="editorId"
      :defaultConfig="editorConfig"
      :defaultContent="getDefaultContent"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import {
  Editor,
  Toolbar,
  getEditor,
  removeEditor,
} from '@wangeditor/editor-for-vue';
import AliOss from '@/utils/alioss';
import cloneDeep from 'lodash.clonedeep';
import { message } from 'ant-design-vue';
export default Vue.extend({
  name: 'MyEditor',
  props: {
    defaultContent: {
      // 编辑器的默认内容，只在初始化时使用
      type: Array,
      default: () => [],
    },
  },
  components: { Editor, Toolbar },
  data() {
    return {
      editorId: 'wangEditor-1', // 定义一个编辑器 id ，要求：全局唯一且不变。重要！！！
      latestContent: [], // 用于存储编辑器最新的内容，onChange 时修改
      latestContentJson: '', // 用于存储编辑器最新json的内容，onChange 时修改
      latestContentHtml: '', // 用于存储编辑器最新Html的内容，onChange 时修改
      toolbarConfig: {
        excludeKeys: ['fullScreen', 'insertVideo'], // 排除菜单项
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            // 自定义上传
            customUpload: async (files, insertFn) => {
              // files 即选中的文件
              // 自己实现上传，并得到图片 url alt href
              // 最后插入图片
              //testUrl= 'https://hzjt-yunzaojia-dev.oss-cn-beijing.aliyuncs.com/20211213/qpp6t8vnaui-6.jpg';
              let url = '';
              let alt = '';
              let href = '';
              if (!files) return false;
              const file = Array.isArray(files) ? files[0] : files;
              url = await new AliOss().upload(file);
              console.log('oss-url:', url);
              // console.log(url);
              insertFn(url, alt, href);
            },
          },
          insertLink: {
            checkLink: (text, url) => {
              if (!url) {
                return;
              }
              // console.log(url);
              if (url.indexOf('http') !== 0) {
                message.error('链接必须以 http/https 开头');
                return;
              }
              return true;
            },
          },
          editLink: {
            checkLink: (text, url) => {
              if (!url) {
                return;
              }
              // console.log(url);
              if (url.indexOf('http') !== 0) {
                message.error('链接必须以 http/https 开头');
                return;
              }
              return true;
            },
          },
        },
      },
    };
  },
  computed: {
    getDefaultContent() {
      console.log(this.defaultContent)
      return cloneDeep(this.defaultContent);
    },
  },
  methods: {
    onChange(editor) {
      // console.log('onChange', editor.children); // onChange 时获取编辑器最新内容
      // console.log('getAllMenuKeys');
      // console.log(editor.getAllMenuKeys());
      this.latestContent = editor.children;
      this.latestContentJson = JSON.stringify(editor.children);
      this.latestContentHtml = editor.getHtml();
    },
  },
  beforeDestroy() {
    const editor = getEditor(this.editorId);
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器 ，重要！！！
    removeEditor(this.editorId);
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.editorWrap {
  border: 1px solid #d9d9d9;
}
.toolbar {
  border-bottom: 1px solid #d9d9d9;
}
.editor {
  height: 300px;
}
</style>
