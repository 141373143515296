




























import Vue from 'vue';
export default Vue.extend({
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    changeCollapse() {
      this.$emit('changeCollapse', !this.collapsed);
    },
    logout() {
      this.$store.dispatch('logout');
      location.reload(); // 退出登录后重新加载页面，清除routers
    },
  },
});
