









































import { Tag } from '@/interface';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'opened-routes',
  data() {
    return {
      path: this.$route.path,
    };
  },
  computed: {
    ...mapGetters(['tags']),
  },
  watch: {
    '$route.path'(val) {
      this.path = val;
    },
  },
  methods: {
    changeTab(activeKey: string) {
      const tag = this.tags.find((t: Tag) => t.path === activeKey);
      this.$router.push({ path: activeKey, query: tag?.query });
    },
    editTab(targetKey: string) {
      const filterTags = this.tags.filter((tag: Tag) => tag.path !== targetKey);
      this.$store.commit('SET_TAGS', filterTags);
      if (this.path === targetKey) {
        const [last] = filterTags.slice(-1);
        this.$router.push({ path: last.path, query: last?.query });
      }
    },
    itemClick({ key }: { key: string }) {
      if (key === this.$route.path) return;
      this.changeTab(key);
    },
  },
});
