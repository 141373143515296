/*
 * @Description: axios request
 * @Author: zhangguoliang
 * @Date: 2021-08-03 16:11:11
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-12-27 10:32:01
 */
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { message } from 'ant-design-vue';
const request = axios.create({
  // baseURL: process.env.VUE_APP_API_URL+'api',
  baseURL: '/api',
  timeout: 10000,
});
request.interceptors.request.use(
  (config) => {
    const { headers } = config;
    const { token = '' } = store.getters;
    const newHeaders = {
      ...headers,
      PRODUCT_CODE: 'SUPER_ADMIN',
      AGENCY_CODE: 'SUPER_ADMIN',
      APPLICATION_NAME: 'MANAGE',
    };
    if (token) {
      newHeaders.Authorization = `Bearer ${token}`;
    }
    return {
      ...config,
      headers: newHeaders,
    };
  },
  (err) => Promise.reject(err)
);

request.interceptors.response.use(
  (res) => {
    const { data, status } = res;
    const mess = data.message || 'internal server error';
    if (status >= 400 || data.status >= 400) {
      if (
        status === 401 ||
        status === 403 ||
        data.status === 401 ||
        data.status === 403
      ) {
        store.dispatch('logout');
        router.push({ path: '/login' });
      }
      message.error(mess);
      return Promise.reject(mess);
    }
    return data;
  },
  (err) => Promise.reject(err)
);

export default request.request;
