































































import Vue from 'vue';
import { FormItemConfig } from '@/interface';
const componentsMap: Record<string, string> = {
  input: 'a-input',
  radio: 'a-radio-group',
  select: 'a-select',
  switch: 'a-switch',
  checkbox: 'a-checkbox-group',
  date: 'a-date-picker',
  textarea: 'a-textarea',
  password: 'a-input-password',
  upload: 'a-upload',
  number: 'a-input-number',
};

type Data = {
  componentsMap: Record<string, string>;
};

type Computed = {
  col: Record<string, any>;
  columns: FormItemConfig[];
  formState: Record<string, any>;
  formVisible: boolean;
};

type Props = {
  config: FormItemConfig[];
  value: Record<string, any>;
  visible: boolean;
  formAttrs: Record<string, any>;
  modelAttrs: Record<string, any>;
  submit: boolean;
  reset: boolean;
};

export default Vue.extend<Data, any, Computed, Props>({
  name: 'CommonForm',
  props: {
    config: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: undefined,
    },
    formAttrs: {
      type: Object,
      default: () => ({}),
    },
    modelAttrs: {
      type: Object,
      default: () => ({}),
    },
    submit: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentsMap,
    };
  },
  computed: {
    col() {
      const { labelWidth = 80 } = this.formAttrs;
      return {
        labelCol: {
          span: 1,
          style: { width: labelWidth + 'px' },
        },
        wrapperCol: {
          span: 1,
          style: { width: `calc(100% - ${labelWidth}px)` },
        },
      };
    },
    columns() {
      return this.config.map((cfg: FormItemConfig) => {
        const {
          innerConfig = {},
          itemConfig: { label },
        } = cfg;
        return {
          ...cfg,
          innerConfig: {
            ...innerConfig,
            events: innerConfig.events || {},
            slots: innerConfig.slots || [],
            width: '100%',
            placeholder: innerConfig.placeholder || `请输入${label}`,
          },
        };
      });
    },
    formState: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
    formVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  watch: {
    submit(val) {
      if (val) {
        this.submitForm();
        this.$emit('update:submit', false);
      }
    },
    reset(val) {
      if (val) {
        this.resetForm();
        this.$emit('update:reset', false);
      }
    },
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate((valid: boolean) => {
        if (!valid) return;
        this.$emit('submitForm', this.formState);
      });
    },
    resetForm() {
      this.$refs.formRef.resetFields();
      this.$emit('resetForm');
    },
  },
});
