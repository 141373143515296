/*
 * @Description:
 * @Author: zhangguoliang
 * @Date: 2021-08-02 17:09:13
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-11-19 17:51:08
 */
import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';
import common from './common';
import RootState from './interface';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    user,
    common,
  },
});
