import {
  BaseResponse,
  LoginByPassword,
  LoginBySmsCode,
  SMS_CODE_TYPE,
} from '@/interface';
import request from '@/utils/request';
import Qs from 'qs';

/*
 * @Author: zhangguoliang
 * @Date: 2021-11-30 17:25:32
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-12-23 21:11:04
 */
export function login(data: LoginByPassword | LoginBySmsCode): Promise<{
  access_token: string;
}> {
  return request({
    url: 'auth-resource/oauth/token',
    method: 'POST',
    data: Qs.stringify(data),
    headers: {
      Authorization: 'Basic U1VQRVJfQURNSU46eGpsaWRvbmcxMjM=',
    },
  });
}

export function getSmsCode(
  mobile: string,
  type: SMS_CODE_TYPE
): Promise<BaseResponse> {
  return request({
    url: `/auth-resource/1/${type}/${mobile}/smsCode`,
    method: 'GET',
  });
}

export function checkMobile(
  mobile: string,
  type: SMS_CODE_TYPE
): Promise<BaseResponse> {
  return request({
    url: `/passport-resources/1/passport/${type}/${mobile}/checkMobile`,
    method: 'GET',
  });
}

export function getCaptcha(uuid: string): Promise<Blob> {
  return request({
    url: `auth-resource/1/captcha?uuid=${uuid}`,
    method: 'GET',
    responseType: 'blob',
  });
}

interface ChangePass {
  mobile: string;
  mobileVerifyCode: string;
  pwd: string;
  pwd2: string;
}

export function changePass(data: ChangePass): Promise<BaseResponse> {
  return request({
    url: `/auth-resource/1/reset`,
    method: 'POST',
    data,
  });
}
