


















import Vue from 'vue';
import AliOss from '@/utils/alioss';
export default Vue.extend({
  name: 'uploadFile',
  data() {
    return {};
  },
  methods: {
    async uploadFile(params: any) {
      const file = params.file;
      let url = '';
      if (!file) return false;
      url = await new AliOss().upload(file);
      this.$emit('updateUrl', url);
    },
    beforeUpload(file) {
      const { name, size } = file;
      const ext = name.replace(/.*\./, '').toLowerCase();
      if (!['jpg', 'png', 'jpeg'].includes(ext)) {
        this.$message.error('所传素材格式不支持');
        return false;
      }
      if (size / 1024 / 1024 > 10) {
        this.$message.error('所传素材超过10MB');
        return false;
      }
      return true;
    },
  },
});
