/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 10:26:26
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-12-14 16:40:32
 */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'root',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { title: '工作台' },
      },
    ],
  },
];

// 固定路由，不会被添加到tags中
export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '平台用户登录' },
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/changePassword/index.vue'),
    meta: { title: '平台用户修改密码' },
  },
  {
    path: '/relationship',
    name: 'relationship',
    component: () => import('@/views/activity/distributionManage/relationship.vue'),
    meta: { title: '下级关系导图' },
  },
];

export const errorRoutes: RouteConfig[] = [
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/error/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes, ...constantRoutes],
});

export default router;
